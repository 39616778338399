export const  colorsMapping = [
  "match",
  ["get", "industry"],
  "Software",
  "#FF8C00",
  "Telecommunications",
  "#2c5784",
  "Information Technology",
  "#e9c46a",
  "Mobility",
  "#2d6a4f",
  "Internet",
  "#9a031e",
  "Aviation",
  "#E75A7C",
  "Hardware",
  "#4CB963",
  "Supermarket",
  "#008000",
  "MedTech",
  "#1a202c",
  "Cloud Computing",
  "#1982C4",
  "Insurance",
  "#503D42",
  "Media",
  "#735290",
  "Payments",
  "#724CF9",
  "Travel",
  "#724CF9",
  "Fashion",
  "#503D42",
  "Sport",
  "#1982C4",
  "Energy",
  "#1982C4",

  "#FF0000", // any other store type
];
